import {graphql, useStaticQuery} from "gatsby";
import React from "react";

export default function EmailLinks() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contactUsPage {
            emailLinks {
              areas {
                email
                subItems
                title
              }
            }
          }
        }
      }
    }
  `);

  const metadata = data.site.siteMetadata.contactUsPage.emailLinks;
  return (
    <div className="content-padding">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:px-8">
        {metadata.areas.map((area) => (
          <div
            className={`${
              (area.subItems ?? []).length > 0 ? "row-span-3" : ""
            } flex flex-col`}
          >
            <a href={`mailto:${area.email}`}>{area.title}</a>
            <ul>
              {(area.subItems ?? []).map((subItem) => (
                <li className="text-black">{subItem}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}
