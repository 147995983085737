import {graphql, useStaticQuery} from "gatsby";
import React from "react";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default function MessageForm() {
  const [state, setState] = React.useState({});
  const [thankYouVisible, setThankYouVisible] = React.useState(false);

  const handleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: {"Content-Type": "application/x-www-form-urlencoded"},
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        document.contact_us.reset();
        setThankYouVisible(true);
      })
      .catch((error) => alert(error));
  };

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contactUsPage {
            sendMessageForm {
              title
              emailPlaceholder
              companyNamePlaceholder
              lastNamePlaceholder
              firstNamePlaceholder
              privacyDisclaimer
              questionTextPlaceholder
              sendMessageButton
            }
          }
        }
      }
    }
  `);

  const inputClassName =
    "w-full placeholder:text-gray-400 rounded-md py-4 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 font-light";
  const metadata = data.site.siteMetadata.contactUsPage.sendMessageForm;
  return (
    <div className="bg-light-gray flex flex-col items-center py-16 content-padding">
      <div className="max-w-4xl">
        <div className="section-title mb-10">{metadata.title}</div>
        <form
          name="contact_us"
          data-netlify="true"
          method="post"
          data-netlify-honeypot="hp-field"
          onSubmit={handleSubmit}
          className="flex flex-col space-y-6"
        >
          {/* additional field for netlify form submission */}
          <input
            type="hidden"
            name="form-name"
            value="contact"
            onChange={handleChange}
          />
          <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
            <p>
              <input
                className={inputClassName}
                placeholder={metadata.firstNamePlaceholder}
                type="text"
                name="firstName"
                onChange={handleChange}
              />
            </p>
            <p>
              <input
                className={inputClassName}
                placeholder={metadata.lastNamePlaceholder}
                type="text"
                name="lastName"
                onChange={handleChange}
              />
            </p>
          </div>
          {/* honeypot */}
          <p class="hidden">
            <label>
              Don’t fill this out if you’re human:{" "}
              <input name="hp-field" onChange={handleChange} />
            </label>
          </p>
          <p>
            <input
              className={inputClassName}
              placeholder={metadata.emailPlaceholder}
              type="email"
              name="email"
              onChange={handleChange}
            />
          </p>
          <p>
            <input
              className={inputClassName}
              placeholder={metadata.companyNamePlaceholder}
              type="text"
              name="company"
              onChange={handleChange}
            />
          </p>
          <p>
            <textarea
              rows="5"
              className={`${inputClassName}`}
              placeholder={metadata.questionTextPlaceholder}
              type="text"
              name="question"
              onChange={handleChange}
            />
          </p>
          <div className="hidden text-gray-600 font-light">
            {metadata.privacyDisclaimer}
          </div>
          <p>
            <button
              className="px-12 py-3 text-white bg-footer-blue rounded-3xl self-start"
              type="submit"
            >
              {metadata.sendMessageButton}
            </button>
          </p>
        </form>
      </div>
      <div
        className={`${
          thankYouVisible ? "block" : "hidden"
        } absolute inset-0 h-full w-full bg-black bg-opacity-40 z-30`}
      >
        <div className="bg-white fixed inset-x-0 md:inset-x-1/4 xl:inset-x-1/3 mx-4 top-12 pt-12 px-8 pb-8 rounded-3xl flex flex-col">
          <span className="pb-8">
            Thank you, our team will contact you ASAP
          </span>
          <button
            className="px-12 py-3 text-white bg-footer-blue rounded-3xl"
            onClick={() => setThankYouVisible(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
