import React, {useState} from "react";
import Header from "src/components/common/header";
import CommonHelmet from "src/components/common/helmet";
import Footer from "src/components/common/footer";
import Hero from "src/components/pages/contact_us/hero";
import EmailLinks from "src/components/pages/contact_us/email_links";
import MessageForm from "src/components/pages/contact_us/message_form";
import GetInTouch from "src/components/pages/contact_us/get_in_touch";

export default function AboutUsPage() {
  return (
    <main className="font-sans text-black flex flex-col items-center justify-center">
      <div className="max-w-7xl relative">
        <Header heroStyle="light" />
        <CommonHelmet />
        <Hero />
        <EmailLinks />
        <MessageForm />
        <GetInTouch />
        <Footer />
      </div>
    </main>
  );
}
