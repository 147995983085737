import {graphql, useStaticQuery} from "gatsby";
import React from "react";

export default function GetInTouch() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contactUsPage {
            getInTouch {
              address
              email
              title
            }
          }
        }
      }
    }
  `);
  const metadata = data.site.siteMetadata.contactUsPage.getInTouch;
  return (
    <div className="content-padding">
      <div className="flex flex-col md:flex-row justify-between bg-light-gray rounded-3xl h-auto md:h-80">
        <div className="md:w-2/4 px-6 pt-8 md:px-12 text-left pb-12">
          <div className="text-2xl md:text-4xl pb-6">{metadata.title}</div>
          <div className="text-dark-gray">
            {metadata.address.map((line) => (
              <span>
                {line}
                <br />
              </span>
            ))}
          </div>
          <div>
            <a className="text-accent-red" href={`mailto:${metadata.email}`}>
              {metadata.email}
            </a>
          </div>
        </div>
        <iframe
          className="md:w-2/4 h-72 md:h-auto"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.801856981269!2d103.85810625125167!3d1.2933502621077555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19a93137e8e5%3A0xb7cf6c1c3b42618!2sCentennial%20Tower!5e0!3m2!1sen!2shk!4v1613555116586!5m2!1sen!2shk"
          aria-hidden="false"
        />
      </div>
    </div>
  );
}
